import React from 'react'

export default props => (
    <section className={ 'cards cards-' + ( props.columns || '3') }>
        <div className="wrapper">
            {props.title &&
                <div className="text-wrapper">
                    <h2>
                        {props.title}

                        {props.subtitle &&
                            <span>{props.subtitle}</span>
                        }
                    </h2>
                </div>
            }
            <div className="cards-wrapper">
                {props.cards && props.cards.map((card, i) => (
                    <div className="card" key={i}>
                        <div className="card-inner text-wrapper">
                            <h3>{card.title}</h3>
                            <p>{card.content}</p>
                            <div className="icon" style={{ backgroundImage: 'url(\'' + card.image + '\')' }} role="img" aria-label={ card.alt }></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
)
