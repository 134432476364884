import React from 'react'

export default () => (
    <section className="testimonials">
        <div className="wrapper">
            <div className="text-wrapper">
                <h2>What they say. <span>Because modesty is overrated</span></h2>
            </div>
            <div className="testimonial-list">
                <div className="testimonial">
                    <i className="fas fa-quote-left"></i>
                    <p className="quote">Hiring and retaining the right talent is paramount for the success of a tech startup, especially in market as a competitive as customer experience. techrec have been our technical hiring partner from the start, constantly advising us on how to keep the hiring process lean and effective. techrec thoroughly pre-screen and prepare suitable candidates, guide them through the process and follow them through the whole duration of the probation period guaranteeing a successful retention of talent. I have also been through this process myself ;)</p>
                    <span className="line"></span>
                    <img src="/images/michele-sama.jpeg" alt="a headshot of Michele Sama" />
                    <p className="from">Michele Sama, CTO @ Kare</p>
                </div>
                <div className="testimonial">
                    <i className="fas fa-quote-left"></i>
                    <p className="quote">techrec are one of the best tech recruiters I've worked with over the last few years. They’re spot on with candidates and ultimately make my life so much easier in getting the right engineers. Great recruiters are extremely difficult to find. And I found one in techrec. Great work!</p>
                    <span className="line"></span>
                    <img src="/images/chee-wong.jpg" alt="a headshot of Chee Wong" />
                    <p className="from">Chee Wong, ex COO @ Shazam & Tech Advisor / Investor</p>
                </div>
                <div className="testimonial">
                    <i className="fas fa-quote-left"></i>
                    <p className="quote">the techrec team really take the time to understand our requirements to provide us with a pipeline of relevant candidates. Recruiting the right candidate is an onerous task and you want to work with someone that provides quality over quantity.</p>
                    <span className="line"></span>
                    <img src="/images/randal-pinto.jpeg" alt="a headshot of Randal Pinto" />
                    <p className="from">Randal Pinto, co-founder @ RedSift</p>
                </div>
            </div>
        </div>
    </section>
)
