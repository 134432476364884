import React from 'react'
import Helmet from 'react-helmet'

import '../less/style.less'

import Header from '../components/header'
import Logos from '../components/logos'
import Info from '../components/info'
import Cards from '../components/cards'
import Testimonials from '../components/testimonials'
import Contact from '../components/contact'
import Footer from '../components/footer'

export default () => (
    <div>
        <Helmet title="techrec - We help tech companies build high performing, diverse teams">
            <html lang="en" />

            <meta name="description" content="We believe that people are a company’s greatest asset. That's why we help tech companies build high performing, diverse tech teams." />

            <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
            <link rel="manifest" href="/images/favicon/site.webmanifest" />
            <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#2c3956" />
            <link rel="shortcut icon" href="/images/favicon/favicon.ico" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-config" content="/images/favicon/browserconfig.xml" />
            <meta name="theme-color" content="#ffffff" />

            <meta property="og:url" content="https://www.techrec.cc" />
            <meta property="og:title" content="We build tech teams." />
            <meta property="og:description" content="It’s as simple as that. We help tech companies build high performing, diverse teams." />
            <meta property="og:image" content="/images/techrec-team.svg" />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content="TechRecIT" />
            <meta name="twitter:title" content="We build tech teams." />
            <meta name="twitter:description" content="It’s as simple as that. We help tech companies build high performing, diverse teams." />
            <meta name="twitter:image" content="/images/techrec-team.svg" />

            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />
        </Helmet>

        <Header
            size="full"
            background="/images/techrec-banner.png"
            heading="We build tech teams."
            content="It’s as simple as that. We help tech companies build high performing, diverse teams."
            buttons={true}
        />

        <Logos logos={[
            {
                image: '/images/babylonhealth.png',
                alt: 'the babylon health logo',
                url: '#'
            },
            {
                image: '/images/karehq.png',
                alt: 'the karehq logo',
                url: '#'
            },
            {
                image: '/images/lantum.png',
                alt: 'the lantum logo',
                url: '#'
            },
            {
                image: '/images/openenergi.png',
                alt: 'the openenergi logo',
                url: '#'
            },
            {
                image: '/images/redsift.png',
                alt: 'the redsift logo',
                url: '#'
            },
            {
                image: '/images/songkick.png',
                alt: 'the songkick logo',
                url: '#'
            }
        ]} />

        <Info>
            <h2>What we do. <span>On the daily</span></h2>
            <p>People are a company’s greatest asset and we help tech companies recruit great people. We’ve helped startups grow their team following successful funding rounds; helped companies launch and grow tech hubs in various European cities and also helped seed stage startups hire their first few employees. We always aim to build diverse teams, because diverse teams perform better.</p>
            <p>Whether you’re looking to hire a single person or a large team, drop us a message, we’d love to hear from you.</p>
        </Info>

        <Cards columns="3" cards={[
            {
                title: 'DEFINE',
                content: 'Tell us about the sort of people you are looking for and the skills required.',
                image: '/images/techrec-define.svg',
                alt: 'an illustration of a CV'
            },
            {
                title: 'SEARCH',
                content: 'We identify the best possible people and reach out to them on your behalf.',
                image: '/images/techrec-search.svg',
                alt: 'an illustration of a man using binoculars'
            },
            {
                title: 'CONNECT',
                content: 'We introduce you to people that fit your requirements and would like to join your mission!',
                image: '/images/techrec-connect.svg',
                alt: 'an illustration of a woman holding a box'
            },
        ]} />

        <Info classes="bg-grey-lighter">
            <h2>Diversity. <span>It matters</span></h2>
            <p>Tech companies rely on innovation to be truly valuable and when team members are too similar, a large part of the innovation spectrum is missed. Innovation happens when teams look beyond the obvious and work creatively.</p>
            <p>If everyone on the team has the same knowledge, the same skills, the same views and the same biases, they will inadvertently reach the same conclusions. Diversity is the best way to avoid this trap and build a truly creative, innovative team culture.</p>
            <p>Get in touch to see how techrec can help you build a diverse team.</p>
        </Info>

        <Testimonials />

        <Cards
        columns="2"
        title="Roles we hire for. "
        subtitle="We love these people"
        cards={[
            {
                title: 'LEADERSHIP',
                content: 'C-Level, VP, “Head of” and Managerial Positions. We can help you find the leaders that will inspire teams and take your company to the next level.',
                image: '/images/techrec-leadership.svg',
                alt: 'an illustration of a woman wearing a cape',
            },
            {
                title: 'SOFTWARE',
                content: 'Backend, Frontend, Data, DevOps, iOS, Android and everything in between. Finding top quality Software Engineers is our bread and butter.',
                image: '/images/techrec-software.svg',
                alt: 'an illustration of a man working at a desk',
            },
            {
                title: 'RESEARCH',
                content: 'Researchers, Engineers and Data Scientists. Many with PhD’s in various subjects within Computer Science, Machine Learning other STEM subjects.',
                image: '/images/techrec-research.svg',
                alt: 'an illustration of a woman holding a clipboard',
            },
            {
                title: 'PRODUCT',
                content: 'Product Managers, UI & UX designers. The people that understand the user and help steer the product in the right direction.',
                image: '/images/techrec-product.svg',
                alt: 'an illustration of a man pressing a button',
            },
        ]} />

        <Contact />

        <Footer />

    </div>
)
