import React from 'react'

export default ({ children }) => (
    <section id="about" className="info">
        <div className="wrapper">
            <div className="text-wrapper">
                {children}
            </div>
        </div>
    </section>
)
