import React from 'react'

export default props => (
    <section className="logos">
        <div className="wrapper">
            <div className="logo-list">
                {props.logos && props.logos.map((logo, i) => (
                    <div className="logo" key={i}>
                        <a href={logo.url}>
                            <img src={logo.image} alt={logo.alt} />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    </section>
)
